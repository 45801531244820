import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { SearchInput } from '../..';
import { Option } from '.';
import { useTranslate } from '@mspecs/shared-utils';
import { BUTTON_TYPES, Button } from '../../../../button';

const ButtonContainer = styled(Flex)`
    gap: 10px;
    width: 100%;
    padding: 10px;
`;

const SearchContainer = styled(Flex)`
    width: 100%;
    padding: 10px;
`;

const StyledSearch = styled(SearchInput)`
    input {
        background: ${({ theme }) => theme.colors.bgSecondary};
    }
`;

const valueMapper = item => (typeof item === 'string' ? item : item?.value);

const MultiSelectOption = props => {
    const { clearValue, setValue, options } = props;

    const { t } = useTranslate();
    const values = props.selectProps?.value?.map(valueMapper) ?? [];

    const isAllSelected = options
        .map(valueMapper)
        .every(value => values.includes(value));

    const isSearching =
        props.selectProps.isSearchable && props.selectProps.inputValue;

    const onSelect = e => {
        e.stopPropagation();
        e.preventDefault();
        isAllSelected
            ? clearValue()
            : setValue(options.filter(x => !x.isDisabled));
    };

    if (isSearching) return null;
    return (
        <Option
            data={{ label: t('MULTI_SELECT_SELECT_ALL') }}
            {...props}
            isSelected={isAllSelected}
            innerProps={{
                ...props.innerProps,
                onTouchEnd: onSelect,
                onClick: onSelect,
            }}
        />
    );
};

const SearchSelectButtonToolbar = props => {
    const { t } = useTranslate();
    return (
        <ButtonContainer>
            <Button
                label={t('CLEAR')}
                buttonType={BUTTON_TYPES.SECONDARY}
                fluid
                onClick={props.clearValue}
            />
            <Button
                label={t('DROPDOWN_SEARCH_DONE_BUTTON_TEXT')}
                buttonType={BUTTON_TYPES.SECONDARY}
                fluid
                onClick={props.selectProps.onMenuClose}
            />
        </ButtonContainer>
    );
};
const SearchSelectInput = props => {
    const ref = useRef();
    return (
        <SearchContainer onClick={() => ref.current?.focus?.()}>
            <StyledSearch
                value={props.selectProps.inputValue}
                autoFocus
                onChange={e => props.selectProps.onInputChange(e.target.value)}
            />
        </SearchContainer>
    );
};

const MenuList = props => {
    const { isExplicitSearchBar, isSearchable } = props.selectProps;
    const showCustomSearch = isSearchable && isExplicitSearchBar;
    const isGrouped = props.options?.some(x => x.options);

    return (
        <>
            {showCustomSearch && <SearchSelectInput {...props} />}
            {/* Not supported for grouped options */}
            {props.isMulti && !isGrouped && <MultiSelectOption {...props} />}
            <components.MenuList {...props} />
            {showCustomSearch && <SearchSelectButtonToolbar {...props} />}
        </>
    );
};

MenuList.propTypes = {
    clearValue: PropTypes.func,
    isMulti: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.object,
            PropTypes.shape({
                value: PropTypes.any.isRequired,
                label: PropTypes.any.isRequired,
            }),
        ])
    ),
    setValue: PropTypes.func,
    selectProps: PropTypes.object,
    innerProps: PropTypes.object,
};

export default MenuList;
