import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import {
    BodyTextRegular,
    Header3Text,
} from '../typograph/typograph-components';
import Modal from './modal-component';
import { useTranslate } from '@mspecs/shared-utils';
import { BUTTON_TYPES, Button, ButtonToolbar } from '../button';

const contentClass = css`
    display: flex;
    flex-direction: column;
`;

const InfoContent = styled.div`
    flex-grow: 1;
    padding-bottom: ${({ theme }) => theme.spacing.large};
`;
const InfoText = styled(BodyTextRegular)`
    padding-bottom: ${({ theme }) => theme.spacing.default};
`;

const StyledButton = styled(Button)`
    width: 100%;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        width: unset;
    }
`;

const HeaderText = styled(Header3Text)`
    width: 100%;
    text-align: left;
    padding-bottom: ${({ theme }) => theme.spacing.small};
`;

const InfoModal = ({ children, infoText, headerText, isOpen, onCancel }) => {
    const { t } = useTranslate();
    return (
        <Modal
            size="s"
            contentClass={contentClass}
            headerText={
                <HeaderText>
                    {t(headerText ?? 'INFO_MODAL_HEADER_TEXT')}
                </HeaderText>
            }
            isOpen={isOpen}
            onClose={onCancel}
        >
            <InfoContent>
                <InfoText>{t(infoText)}</InfoText>
                {children}
            </InfoContent>
            <ButtonToolbar>
                <StyledButton
                    label="OK"
                    large
                    onClick={onCancel}
                    buttonType={BUTTON_TYPES.PRIMARY}
                />
            </ButtonToolbar>
        </Modal>
    );
};

export default InfoModal;

InfoModal.propTypes = {
    children: PropTypes.any,
    onCancel: PropTypes.func,
    infoText: PropTypes.string,
    headerText: PropTypes.string,
    isOpen: PropTypes.bool,
};

InfoModal.defaultProps = {
    onCancel: x => x,
};
