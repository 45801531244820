import LanguageDetector from 'i18next-browser-languagedetector';
import React from 'react';
import XHR from 'i18next-xhr-backend';
import i18n from 'i18next';
import sprintf from 'i18next-sprintf-postprocessor';
import wrapDisplayName from 'recompose/wrapDisplayName';
import { gql, useQuery } from '@apollo/client';

import { LEGACY_API_PREFIX_PATH } from '../constants/url-contants';
import { capitalize } from '../word-utils';

const defaultLang = 'sv';
const noTranslateKey = 'noTranslate';
export const defaultNamespace = 'localisation';
export const dbFieldNamespace = 'db-fields';
export const availableLanguages = [defaultLang, 'en', noTranslateKey];
const languageKey = 'i18nextLng';
const currentLanguage = () => localStorage.getItem(languageKey);
const options = {
    order: ['localStorage', 'navigator'],
};
i18n.use(XHR)
    .use(sprintf)
    .use({
        name: 'capitalize',
        type: 'postProcessor',
        process: capitalize,
    })
    .use(LanguageDetector)
    .init({
        detection: options,
        useLocalStorage: true,
        localStorageExpirationTime: 86400000,
        fallbackLng: defaultLang,
        lng: defaultLang,
        whitelist: availableLanguages,
        load: 'languageOnly',
        ns: [defaultNamespace, dbFieldNamespace],
        backend: {
            loadPath: (lng, ns) => {
                const namespace = ns.pop();
                if (namespace === dbFieldNamespace) {
                    return `${LEGACY_API_PREFIX_PATH}/localisation/db-fields/locale-{{lng}}.json`;
                }
                return `${LEGACY_API_PREFIX_PATH}/{{ns}}/locale-{{lng}}.json`;
            },
        },
    })
    .then(() => i18n.emit('translations-loaded'))
    .catch(err => {
        // eslint-disable-next-line no-console
        if (err) {
            console.log(err);
        }
    });

// TODO - We need to connect this to the angular part
// So far we only use this on login page, then the users session language will kick in
const changeLanguage = language => {
    i18n.changeLanguage(language).catch(error => {
        console.log('Error in changing language.');
        console.log(error);
        throw error;
    });
    localStorage.setItem(languageKey, language);
    if (!language && this.services.languageDetector)
        language = this.services.languageDetector.detect();
};

const getT = constant => {
    return getFixedT(i18n.language, defaultNamespace)(constant);
};
const isNotTranslated = (translation = '', constant = '') =>
    translation?.toUpperCase?.() === constant?.toUpperCase?.();

const getFixedT = (language, defaultNamespace) => {
    const t = i18n.getFixedT(language, defaultNamespace);
    /* This is so that translationKeys gets printed even in production
    if (NODE_ENV === 'production') {
        return t;
    } */

    return (translationConstant, proviedOptions = {}) => {
        const usedNamespace = proviedOptions.ns ?? defaultNamespace;
        const usedDBNamespace = usedNamespace === dbFieldNamespace;
        const options = {
            keySeparator: usedDBNamespace ? false : undefined,
            nsSeparator: '::', // Raw text (not translation keys) may include single colon
            ...proviedOptions,
        };
        let translation = t(translationConstant, options);

        if (
            usedDBNamespace &&
            isNotTranslated(translation, translationConstant) &&
            translationConstant?.includes('.')
        ) {
            // Look if generic translations exists
            const field = translationConstant.split('.').pop();
            translation = t(field, options);
        }

        if (isNotTranslated(translation, translationConstant)) {
            console.debug(
                `translation missing for constant ${translationConstant}`
            );
        }

        if (language == noTranslateKey) return translationConstant ?? null;
        if (translation === '') return translationConstant ?? null;
        return translation;
    };
};

/* there is currently no graphql support in the lib_build */
const LANGUAGES_QUERY =
    LIB_BUILD || CUSTOMER_PAGE
        ? () => {}
        : gql`
              query languages {
                  languages {
                      name
                      id
                      isoCode
                  }
              }
          `;
const _useQuery = LIB_BUILD || CUSTOMER_PAGE ? () => ({}) : useQuery;
export const useTranslate = ({ useDBTranslation = false } = {}) => {
    const { data } = _useQuery(LANGUAGES_QUERY);

    const [locale, setLocale] = React.useState({
        language: i18n.language,
    });
    const languages = React.useMemo(() => data?.languages, [data]);

    React.useEffect(() => {
        const langChangeCb = newLang => {
            setLocale({ language: newLang });
        };

        i18n.on('languageChanged', langChangeCb);
        return () => {
            i18n.off('languageChanged', langChangeCb);
        };
    }, []);

    const languageId = React.useMemo(
        () =>
            languages?.find(({ isoCode }) => isoCode === locale.language)?.id ??
            '',
        [languages, locale]
    );

    return {
        language: locale.language,
        languageId,
        // TODO: shouldnt this globaly set the new language?
        changeLanguage: language => setLocale({ language }),
        t: getFixedT(
            locale.language,
            useDBTranslation ? dbFieldNamespace : defaultNamespace
        ),
    };
};

// Wrapper component that injects `language`, `t()`, `changeLanguage()`
const translate = Component => {
    class WithI18n extends React.Component {
        constructor(props) {
            super(props);
            const language = i18n.language;
            this.state = this.getLanguageState(
                language,
                props.useDBTranslation
            );
            i18n.on('languageChanged', this.changeLanguage);
        }

        getLanguageState = (language, useDBTranslation) => ({
            language,
            t: getFixedT(
                language,
                useDBTranslation ? dbFieldNamespace : defaultNamespace
            ),
        });

        changeLanguage = language => {
            this.setState({
                ...this.state,
                ...this.getLanguageState(language, this.props.useDBTranslation),
            });
        };

        componentWillUnmount() {
            i18n.off('languageChanged', this.changeLanguage);
        }

        render() {
            const props = {
                ...this.props,
                language: this.state.language,
                t: this.props.noTranslate ? x => x : this.state.t,
            };
            return <Component {...props} />;
        }
    }

    /*
    For proper interaction with the angular component 'react-directive',
    the propTypes needs to be exposed. Using childPropTypes to circumvent .isRequired issues when
    using propTypes...
    */
    WithI18n.childPropTypes = {
        ...(Component.propTypes || Component.childPropTypes),
    };

    WithI18n.displayName = wrapDisplayName(Component, 'WithI18n');

    /*
    // This will break the cascading propTypes to the angular lib
    // If we take ObjectDescriptionContainer as example. it have propTypes that comes here
    // they get placed in childPropTypes. next step is connect which places propTypes or childPropTypes in
    // childPropTypes which means that propTypes from ObjectDescriptionContainer disappears

    // We remove this for now
    WithI18n.propTypes = {
        noTranslate: PropTypes.bool,
        useDBTranslation: PropTypes.bool,
    };
    */

    return WithI18n;
};

export { translate, changeLanguage, currentLanguage, getT };

export default i18n;
