import React from 'react';
import styled from '@emotion/styled';
import {
    Crumb,
    StyledBreadCrumbs,
} from '../navigation/stack/bread-crumbs-component';
import { useIsMobile } from '@mspecs/shared-utils';
import Headline from '../navigation/navigation-headline-component';
import { ITheme } from '../../types';

interface ContainerProps {
    isScrollable: boolean;
}

export const FrameSpacer = styled('div')<{ theme?: ITheme }>(({ theme }) => {
    return {
        padding: theme.frameSpacing.small,

        [`@media (min-width: ${theme.responsiveBreakpoints.s})`]: {
            padding: theme.frameSpacing.medium,
        },

        [`@media (min-width: ${theme.responsiveBreakpoints.m})`]: {
            padding: theme.frameSpacing.medium,
        },

        [`@media (min-width: ${theme.responsiveBreakpoints.l})`]: {
            padding: theme.frameSpacing.default,
        },

        [`@media (min-width: ${theme.responsiveBreakpoints.xl})`]: {
            padding: theme.frameSpacing.large,
        },
    };
});

const Container = styled(FrameSpacer, {
    shouldForwardProp: prop => prop !== 'isScrollable',
})<ContainerProps & { theme?: ITheme }>(({ isScrollable, theme }) => {
    return {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        width: '100%',
        overflowY: isScrollable ? 'auto' : 'hidden',
        height: isScrollable ? 'auto' : '100%',
        background: theme.colors.bgPrimary,
    };
});

const BreadCrumbsContainer = styled.div<{ theme?: ITheme }>`
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.l}) {
        margin-left: -15px;
    }
`;

const FrameContainer = ({
    breadCrumbs,
    headline,
    children,
    isScrollable = true,
    renderBackButton,
    onAdd,
    ...props
}: FrameContainerProps) => {
    const isSmall = useIsMobile();

    const showBreadCrumbs = !isSmall && !!breadCrumbs;
    const showBackButton = isSmall && !!breadCrumbs && renderBackButton;
    const goBackLinkIndex = breadCrumbs
        ? Math.max(breadCrumbs.length - 2, 0)
        : 0;

    return (
        <Container isScrollable={isScrollable} {...props}>
            {showBreadCrumbs && (
                <BreadCrumbsContainer>
                    <StyledBreadCrumbs>
                        {breadCrumbs.map((crumb, index) => (
                            <Crumb
                                key={index}
                                title={crumb.title}
                                onClick={crumb.onClick}
                                isLast={index === breadCrumbs?.length - 1}
                            />
                        ))}
                    </StyledBreadCrumbs>
                </BreadCrumbsContainer>
            )}
            {showBackButton &&
                renderBackButton({ breadCrumb: breadCrumbs[goBackLinkIndex] })}
            {headline !== '' && (
                <Headline
                    label={
                        headline ||
                        (breadCrumbs && breadCrumbs.length
                            ? breadCrumbs[breadCrumbs.length - 1]?.title
                            : '')
                    }
                    onAdd={onAdd}
                />
            )}
            {children}
        </Container>
    );
};

interface BreadCrumbsProps {
    title: string;
    onClick?: () => void;
    route?: string;
}

interface FrameContainerProps {
    breadCrumbs?: BreadCrumbsProps[];
    headline?: string;
    hideBigBackButton?: boolean;
    isScrollable?: boolean;
    children: React.ReactNode;
    renderBackButton?: (props: any) => React.ReactNode;
    onAdd?: () => void;
}

export default FrameContainer;
