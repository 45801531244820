import React from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import { DefaultSidebarHeader } from './header-components';
import { triggerOnBlur } from '@mspecs/shared-utils';
import { useSidebarContext } from '../../../layout/main-layout/sidebar-state-context';

const SidebarSidePropType = PropTypes.oneOf(['right', 'left']);

const SidebarWrapper = styled(Flex)`
    overflow: hidden;
    flex: 1 1 0;
    flex-direction: column;
    position: ${({ pinned }) => (pinned && 'relative') || 'absolute'};
    z-index: 1;
    height: 100%;
    background: ${({ theme }) => theme.colors.bgPrimary};
    box-shadow: ${({ theme }) => theme.boxShadows.default};

    &.sidebar-enter {
        position: fixed;
        transform: translateX(${({ translateMod }) => translateMod}100%);
    }
    &.sidebar-enter-active {
        position: absolute;
        transform: translateX(0);
        transition: transform 250ms;
    }
    &.sidebar-exit,
    &.sidebar-enter-done {
        transform: translateX(0);
    }
    &.sidebar-exit-active {
        transform: translateX(${({ translateMod }) => translateMod}100%);
        transition: transform 250ms;
    }
    width: 100%;
    border: none;
    ${({ side }) =>
        side === 'left'
            ? `left: 0; margin-right: 5px; z-index: 1;`
            : `right: 0; margin-left: 15px; z-index: 2;`}

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        max-width: 402px;
        width: auto;
        ${({ side, theme }) =>
            side === 'left'
                ? `border-right: 6px solid ${theme.colors.contrastBg85};border-top-right-radius: 6px;`
                : `border-left: 6px solid ${theme.colors.contrastBg85};border-top-left-radius: 6px;`}
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 0px;
    width: 100%;
    overflow-y: auto;
    padding: ${({ theme: { spacing } }) =>
        `${spacing.default} 16px 16px ${spacing.large}`};

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        width: 372px;
        padding: ${({ noMargin, theme: { spacing } }) =>
            noMargin ? '0' : `${spacing.large} ${spacing.large} 35px 25px`};
    }
`;

export const StyledSidebarHeader = styled(DefaultSidebarHeader)`
    border-bottom: ${({ hasTabs }) => hasTabs && '0'};
`;

const InsetSidebar = ({ side = 'left', children }) => {
    const [el, setEl] = React.useState(null);
    React.useEffect(() => {
        setEl(document.querySelector(`#${side}-inset-sidebar`));
    }, []);
    return el && ReactDOM.createPortal(children, el);
};

const SidebarContainer = styled(Flex)`
    z-index: 3;
    overflow: hidden;
`;

export const SidebarPortal = ({ side = 'left' }) => (
    <SidebarContainer flexDirection="column" id={`${side}-inset-sidebar`} />
);

SidebarPortal.propTypes = {
    side: SidebarSidePropType,
};

const Sidebar = ({
    show,
    closeOnOutsideClick,
    side,
    wrapperStyles,
    header,
    onClose,
    isPinnable,
    children,
    pinned,
    headerIcon,
    renderHeader = headerProps => <StyledSidebarHeader {...headerProps} />,
    hasTabs,
    ...props
}) => {
    const sidebarRef = triggerOnBlur(onClose, show);
    const [state] = useSidebarContext();
    const isPinned = state[side].pinned;

    return (
        <InsetSidebar side={side}>
            <CSSTransition
                classNames="sidebar"
                timeout={250}
                appear
                unmountOnExit
                in={show}
            >
                <SidebarWrapper
                    ref={
                        closeOnOutsideClick && !state[side].pinned
                            ? sidebarRef
                            : null
                    }
                    side={side}
                    translateMod={side == 'left' && '-'}
                    pinned={pinned || isPinned}
                    className={wrapperStyles}
                    {...props}
                >
                    <>
                        {renderHeader?.({
                            side,
                            pinned,
                            isPinnable,
                            header,
                            isPinned,
                            onClose,
                            headerIcon,
                            hasTabs,
                        })}
                        <ContentWrapper noMargin={hasTabs}>
                            {show && children}
                        </ContentWrapper>
                    </>
                </SidebarWrapper>
            </CSSTransition>
        </InsetSidebar>
    );
};

Sidebar.propTypes = {
    show: PropTypes.bool.isRequired,
    closeOnOutsideClick: PropTypes.bool,
    header: PropTypes.string,
    children: PropTypes.node,
    onClose: PropTypes.func,
    wrapperStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    side: SidebarSidePropType,
    isPinnable: PropTypes.bool,
    pinned: PropTypes.bool,
    renderHeader: PropTypes.func,
    headerIcon: PropTypes.object,
    hasTabs: PropTypes.bool,
};

Sidebar.defaultProps = {
    side: 'left',
    closeOnOutsideClick: false,
};

export default Sidebar;
