import styled from '@emotion/styled';
import { Box, Flex } from '@rebass/grid/emotion';
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import useScrollProgress from './utils/use-scroll-progress';

const Container = styled(Flex)`
    justify-content: center;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.default}; ;
`;
const Dot = styled(Box)`
    width: 11px;
    height: 11px;
    margin: 0 11px;
    background: ${({ isActive, theme }) =>
        isActive ? theme.colors.pendingPrimary : theme.colors.gray100};
    border-radius: 50%;
    transition: background 0.5s ease;
`;

const ScrollIndicator = forwardRef(({ count }, ref) => {
    const activeDot = useScrollProgress({ ref, numberOfElements: count });

    return (
        <Container>
            {[...Array(count).keys()].map(i => (
                <Dot key={i} isActive={activeDot === i} />
            ))}
        </Container>
    );
});

export default ScrollIndicator;

ScrollIndicator.propTypes = {
    count: PropTypes.number,
};
