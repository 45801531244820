import { useRef, useState } from 'react';

/* 
This hook should probably not live inside the ui-components,
but as the select-component is used so frequently it is placed here temporarily.

One potential solution could be to make a wrapper component living in the frontend-app 
that is responsible for handling the sidebar logic and passing all other props to the select-component.

*/
export default function useIsInsideSidebar(getElement = el => el) {
    const ref = useRef();
    const [isInsideSidebar, setIsInsideSidebar] = useState(false);

    return {
        isInsideSidebar,
        ref: el => {
            if (el) {
                const rightSidebar = document.getElementById(
                    'right-inset-sidebar'
                );
                const leftSidebar = document.getElementById(
                    'right-inset-sidebar'
                );

                const element = getElement(el);
                setIsInsideSidebar(
                    rightSidebar?.contains(element) ||
                        leftSidebar?.contains(element)
                );
            }

            ref.current = el;
        },
    };
}
