import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HiddenContent from '../../styling/hidden-content-component';
import Button, { BUTTON_TYPES } from '../button-component';
import { ButtonTextNormal } from '../../typograph/typograph-components';
import { translate } from '@mspecs/shared-utils';

export const StyledIconButton = styled(Button)`
    font-size: 16px;
    padding: ${({ theme }) => theme.spacing.default};
    width: ${({ small, large, isRound }) => {
        if (!isRound) return '';

        return small ? '30px' : large ? '42px' : '34px';
    }};
`;

const roundButtonStyle = {
    fontSize: '15.6px',
    fontWeight: '300',
    borderRadius: '50%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const IconLabelText = styled(ButtonTextNormal)`
    padding-left: ${({ theme }) => theme.spacing.default};
    line-height: inherit;
    color: inherit;
`;
const IconButton = ({
    icon,
    isRound,
    type,
    hiddenLabel,
    title,
    t,
    style,
    buttonType,
    iconSize,
    label,
    ...rest
}) => {
    const isFontAwesome = icon?.icon && icon?.prefix;

    return (
        <StyledIconButton
            style={style || (isRound ? roundButtonStyle : null)}
            title={t(title)}
            type={type}
            buttonType={
                buttonType !== undefined
                    ? buttonType
                    : BUTTON_TYPES.PRIMARY_LIGHT
            }
            isRound={isRound}
            {...rest}
        >
            {hiddenLabel && <HiddenContent>{t(hiddenLabel)}</HiddenContent>}
            {isFontAwesome ? (
                <FontAwesomeIcon icon={icon} size={iconSize} />
            ) : (
                icon
            )}
            {label && <IconLabelText>{label}</IconLabelText>}
        </StyledIconButton>
    );
};

IconButton.propTypes = {
    hiddenLabel: PropTypes.string.isRequired,
    className: PropTypes.string,
    icon: PropTypes.object,
    isRound: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
};

IconButton.defaultProps = {
    icon: null,
    type: 'button',
};

export default translate(IconButton);
