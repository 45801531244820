import React from 'react';
import PropTypes from 'prop-types';
import { wrapDisplayName } from 'recompose';

const noop = x => x;

export const useInputValidationProps = ({
    validate = noop,
    onBlur = noop,
    onChange = noop,
    invalid,
    additionalErrors,
}) => {
    const [_invalid, setInvalid] = React.useState(!!invalid);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const _validate = value => {
        const result = validate(value);
        setInvalid(!!result);
        setErrorMessage(result);
    };

    const _onBlur = event => {
        const value = event && event.target ? event.target.value : event;
        _validate(value);
        onBlur(event);
    };

    const _onChange = event => {
        const value =
            event && event.target
                ? event.target.type === 'checkbox'
                    ? event.target.checked
                    : event.target.value
                : event;
        _validate(value);
        onChange(event);
    };

    return {
        invalid: !!additionalErrors || _invalid,
        errorMessage: additionalErrors || errorMessage,
        onChange: _onChange,
        onBlur: _onBlur,
    };
};

/**
 * Validates values on change/blur and passes down a valid flag and error message
 */

const withInputValidation = WrappedComponent => {
    const InputValidation = ({ validate, additionalErrors, ...props }) => {
        const validationProps = useInputValidationProps({
            validate,
            additionalErrors,
            ...props,
        });
        return <WrappedComponent {...props} {...validationProps} />;
    };

    InputValidation.displayName = wrapDisplayName(
        WrappedComponent,
        'withInputValidation'
    );

    InputValidation.propTypes = {
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        validate: PropTypes.func,
    };

    return React.memo(InputValidation);
};

export default withInputValidation;
