import { saveAs } from 'file-saver';
import { jsonObjectToQueryString, parseResponseBody } from './http-utils';

export class MspxFetch {
    constructor(url, { delayRequest = false } = {}) {
        this.url = url;
        this.fullUrl = url;
        this._queryParams = {};
        this._postRequestOptions = {};
        this._delayRequest = delayRequest;
        this.options = {
            // credentials needs to be 'include' during production with the proxy setup
            credentials: NODE_ENV !== 'production' ? 'include' : 'same-origin',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };
    }

    isMw() {
        return this._delayRequest;
    }

    _appendQueryParams() {
        const queryString = jsonObjectToQueryString(this._queryParams);
        return `${this.url}${queryString ? `?${queryString}` : ''}`;
    }

    async _request() {
        const response = await fetch(this.fullUrl, this.options);
        if (response.status !== 200 && response.status !== 201) {
            const errorBody = await parseResponseBody(response);
            if (Array.isArray(errorBody) && errorBody.length) {
                throw errorBody[0];
            }
            throw errorBody;
        }

        if (this._saveResponseAsFile) {
            const blob = await response.blob();
            saveAs(blob, this._fileName);
        }

        return parseResponseBody(response);
    }

    exec() {
        if (!this.options.method) {
            throw new Error('Missing rest method');
        }

        if (!this._delayRequest) {
            throw new Error(`
                exec() was called on  mspxFetch, if you are using msxpFetch together with the
                request-mw switch to mspxFetchMW
            `);
        }

        this._delayRequest = false;
        return this._request();
    }

    // _queryParams is an object { foo: 'bar' }
    query(queryParams) {
        this._queryParams = queryParams;
        this.fullUrl = this._appendQueryParams();
        return this;
    }

    get() {
        this.options.method = 'GET';
        if (this._delayRequest) {
            return this;
        }
        return this._request();
    }

    _sendData(body) {
        this.options.body = JSON.stringify(body);
        if (this._delayRequest) {
            return this;
        }
        return this._request();
    }

    put(body) {
        this.options.method = 'PUT';
        return this._sendData(body);
    }

    post(body) {
        this.options.method = 'POST';
        return this._sendData(body);
    }

    postAsForm(data, options = {}) {
        this.options.body = data;
        this.options.method = 'POST';
        this.options = { ...this.options, ...options };
        delete this.options.headers['Content-Type'];
        return this._request();
    }

    createOrUpdate(body) {
        const { id, ...rest } = body;
        if (id) {
            this.url = `${this.url}/${id}`;
            this.fullUrl = this.url;
            return this.put(rest);
        }
        return this.post(body);
    }

    delete(body) {
        if (body) {
            this.options.body = JSON.stringify(body);
        }
        this.options.method = 'DELETE';
        if (this._delayRequest) {
            return this;
        }
        return this._request();
    }

    saveResponseAsFile(fileName) {
        this._saveResponseAsFile = true;
        this._fileName = fileName;
        return this;
    }
}

export default function mspxFetchFactory(url) {
    return new MspxFetch(url);
}

/*
    MspxFetchMW is used together with the request-mw middleware. The main difference between
    MspxFetchMW and mspxFetch is the delayed request, this is needed to properly check pending
    requests before allowing the request to go through.
*/
export function mspxFetchMW(url) {
    return new MspxFetch(url, { delayRequest: true });
}
