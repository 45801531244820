import {
    LEGACY_API_PREFIX_PATH,
    API_PREFIX_PATH,
} from './constants/url-contants';
import { MspxFetch } from './mspx-fetch';

// Old backend
export class LegacyApi extends MspxFetch {
    constructor(url, config) {
        super(`${LEGACY_API_PREFIX_PATH}${url}`, config);
    }
}

// New backend
export class Api extends MspxFetch {
    constructor(url, config) {
        super(`${API_PREFIX_PATH}${url}`, config);
    }
}

export const legacyApi = subPath => new LegacyApi(subPath);
export const api = subPath => new Api(subPath);

/*
    MspxFetchMW is used together with the request-mw middleware. The main difference between
    MspxFetchMW and mspxFetch is the delayed request, this is needed to properly check pending
    requests before allowing the request to go through.
*/
export function legacyApiMW(url) {
    return new LegacyApi(url, { delayRequest: true });
}
