import { ac } from '@mspecs/shared-utils';
import { arrayToObj } from '@mspecs/shared-utils';
import { seedSignatures as _seedSignatures } from './signature-actions';

export const ACTION_TYPES = {
    PRINTMODAL_CLOSE: 'PRINTMODAL_CLOSE',
    PRINTMODAL_ADD_SIGNATURE: 'PRINTMODAL_ADD_SIGNATURE',
    PRINTMODAL_EDIT_SIGNATURE: 'PRINTMODAL_EDIT_SIGNATURE',
    PRINTMODAL_SEED_SIGNATURES: 'PRINTMODAL_SEED_SIGNATURES',
    PRINTMODAL_REMOVE_SIGNATURE: 'PRINTMODAL_REMOVE_SIGNATURE',
    PRINTMODAL_UPDATE_BIDDING_SETTINGS: 'PRINTMODAL_UPDATE_BIDDING_SETTINGS',
    PRINTMODAL_UPDATE_CDS_SETTINGS: 'PRINTMODAL_UPDATE_CDS_SETTINGS',
    PRINTMODAL_UPDATE_BROKER_JOURNAL_SETTINGS:
        'PRINTMODAL_UPDATE_BROKER_JOURNAL_SETTINGS',
    PRINTMODAL_UPDATE_SIGNATURE_DATE_CITY:
        'PRINTMODAL_UPDATE_SIGNATURE_DATE_CITY',
    PRINTMODAL_UPDATE_SIGNATURE_DATE_CITY_GLOBAL:
        'PRINTMODAL_UPDATE_SIGNATURE_DATE_CITY_GLOBAL',
};

const updateSectionValues = type => (name, value) => ac(type, { name, value });

export const updateBiddingPrintOptions = updateSectionValues(
    ACTION_TYPES.PRINTMODAL_UPDATE_BIDDING_SETTINGS
);

export const updateBrokerJournalSettings = updateSectionValues(
    ACTION_TYPES.PRINTMODAL_UPDATE_BROKER_JOURNAL_SETTINGS
);

export const updateCdsSettings = updateSectionValues(
    ACTION_TYPES.PRINTMODAL_UPDATE_CDS_SETTINGS
);

export const updateDateAndCity = updateSectionValues(
    ACTION_TYPES.PRINTMODAL_UPDATE_SIGNATURE_DATE_CITY
);

export const updateDateAndCityGlobal = () =>
    ac(ACTION_TYPES.PRINTMODAL_UPDATE_SIGNATURE_DATE_CITY_GLOBAL);

export const addSignature = signature =>
    ac(ACTION_TYPES.PRINTMODAL_ADD_SIGNATURE, signature);

export const removeSignature = signature =>
    ac(ACTION_TYPES.PRINTMODAL_REMOVE_SIGNATURE, signature);

export const updateSignature = signature =>
    ac(ACTION_TYPES.PRINTMODAL_EDIT_SIGNATURE, signature);

export const close = () => ({
    type: ACTION_TYPES.PRINTMODAL_CLOSE,
});

export function seedSignatures(dealId, showOnlyBuyersWithShare, t) {
    return async dispatch => {
        /*
         * signatures are returned nested from the server
         */
        const { buyers, sellers, brokers } = await _seedSignatures(dealId);
        const filteredBuyers = showOnlyBuyersWithShare
            ? buyers.filter(({ share }) => !!share)
            : buyers;

        const normalizedList = [
            ...filteredBuyers,
            ...sellers,
            ...brokers,
        ].reduce((signatureList, { id, relations, updatedDate, ...rest }) => {
            const { relatedSignatures, consent } = relations;
            return [
                ...signatureList,
                rest,
                ...relatedSignatures,
                ...consent.map(x => ({
                    ...x,
                    headerText: t('CONSENT'),
                })),
            ];
        }, []);
        dispatch(
            ac(
                ACTION_TYPES.PRINTMODAL_SEED_SIGNATURES,
                arrayToObj(normalizedList, {
                    addDisplayOrder: true,
                    generateIds: true,
                })
            )
        );
    };
}
