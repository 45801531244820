import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useUniqueId, hideVisually } from '@mspecs/shared-utils';

const HiddenInput = styled.input`
    ${hideVisually()}
`;

const SwitchContainer = styled.label`
    display: block;
    cursor: pointer;
    width: 45px;
    min-width: 40px;
    height: 25px;
    background: ${props =>
        props.disabled
            ? ({ theme }) => theme.colors.borderPrimary
            : props.value
            ? ({ theme }) => theme.colors.primaryColor
            : ({ theme }) => theme.colors.textSecondary};
    border-radius: 48px;
    position: relative;
    transition: background-color 0.2s;
    margin-right: 10px;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

    border: 2px solid transparent;
    :hover {
        border: 2px solid
            ${({ theme, value, disabled }) => {
                if (disabled) return 'transparent';
                return value
                    ? theme.colors.primaryColor60
                    : theme.colors.gray120;
            }};
    }

    ${HiddenInput}:focus + & {
        ${({ theme, disabled }) => !disabled && theme.input.switch.focus};
    }
`;

const Button = styled.span`
    content: '';
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1px;
    left: 1px;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    transition: 0.2s;
    background: ${({ theme }) => theme.colors.white};
    left: ${props => props.value && 'calc(100% - 1px)'};
    transform: ${props => props.value && 'translateX(-100%)'};
`;

const Container = styled(Flex)`
    height: 34px;
    color: ${({ disabled, theme }) =>
        disabled ? theme.colors.textSecondary : 'inherit'};
`;

const StyledIcon = styled(FontAwesomeIcon)`
    transition: all 0.2s;
    color: ${({ disabled, checked, theme: { colors } }) =>
        disabled || !checked ? colors.textSecondary : colors.primaryColor};
    opacity: ${props => (!props.disabled || props.checked ? 1 : 0)};

    width: 16px;
    height: 16px;
`;
const Switch = ({
    id = undefined,
    label,
    onChange,
    value,
    name = undefined,
    disabled = false,
    ...props
}) => {
    const memoizedId = useUniqueId(id);

    return (
        <Container
            alignItems="center"
            disabled={disabled}
            onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                if (!disabled) {
                    onChange?.(!value);
                }
            }}
            {...props}
        >
            <HiddenInput
                name={name}
                id={memoizedId}
                type="checkbox"
                value={value}
            />
            <SwitchContainer
                htmlFor={memoizedId}
                value={value}
                disabled={disabled}
            >
                <Button value={value} role="switch">
                    <StyledIcon
                        icon={value ? faCheck : faXmark}
                        size="lg"
                        checked={value}
                        disabled={disabled}
                    />
                </Button>
            </SwitchContainer>
            {label}
        </Container>
    );
};

Switch.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]), // can be empty string if a form field
    disabled: PropTypes.bool,
    name: PropTypes.string,
};

Switch.defaultProp = {
    label: '',
    onChange: x => x,
    value: false,
};

export default Switch;
