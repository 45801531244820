import { css } from '@emotion/react';

// Theme based on the 2 scss variables found in the Angular app
// and observations in the production app
import * as colors from './colors';

export const theme = {
    fontSizeSmall: '11px',
    fontSizeNormal: '14px',
    fontWeightNormal: '400',
    fontWeightBold: '700',
};

export const formInput = theme => css`
    color: ${theme.colors.textPrimary};
    margin-bottom: 0;
    height: 34px;
    font-family: Inter;
    font-weight: 400;
    line-height: 21px;
    box-shadow: none;
    padding: 6.5px 10px;
    width: 100%;
    border-radius: 4px;
    background-color: #fff;
    background-image: none;
    border: 1px solid ${theme.colors.borderPrimary};
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    ::placeholder {
        color: ${theme.colors.textSecondary};
    }
    :hover,
    :active,
    :focus {
        box-shadow: ${theme.boxShadows.smallHover};
    }
    :active,
    :focus {
        border: 1px solid ${theme.colors.borderSecondary};
    }

    &[disabled],
    &[readonly] {
        background-color: ${theme.colors.bgSecondary};
        border: 1px solid ${theme.colors.borderPrimary};

        :hover,
        :active,
        :focus {
            box-shadow: none;
        }
        :active,
        :focus {
            border: 1px solid ${theme.colors.borderPrimary};
        }
    }

    @media (max-width: ${theme.responsiveBreakpoints
            .s}) and (orientation: portrait) {
        max-width: none;
    }
    font-size: 16px;
    @media (min-width: ${theme.responsiveBreakpoints.s}) {
        font-size: 14px;
    }
`;

export const increasedInputFontSize = css`
    input,
    input[type='text'],
    input[type='password'] {
        font-size: 16px !important;
    }
`;

export const responsiveBreakpoints = {
    // xs: all below
    s: '600px',
    m: '905px',
    l: '1280px',
    xl: '1536px',
};

export const breakpoints = Object.values(responsiveBreakpoints);
export const medium = responsiveBreakpoints.s; // legacy use
export const large = responsiveBreakpoints.m; // legacy use
export const xlarge = responsiveBreakpoints.l; // legacy use
export const spacing = {
    small: '5px',
    default: '10px',
    large: '20px',
};
export const frameSpacing = {
    small: '16px 16px 50px 16px',
    medium: '24px 24px 35px 24px',
    default: '24px 35px 35px 35px',
    large: '24px 70px 35px 70px',
};
export const borderRadius = {
    default: '4px',
    medium: '8px',
};

const defaultBoxShadow =
    'rgba(0, 0, 0, 0.12) 0px 1px 3px 0px, rgba(0, 0, 0, 0.07) 0px 1px 2px 0px';

export const boxShadows = {
    small: '0px 1px 3px 0px rgba(204, 204, 204, 0.50)',
    smallHover: defaultBoxShadow,
    default: defaultBoxShadow,
    defaultHover: '0px 0px 3px 0px rgba(204, 204, 204, 0.7)',
};

export const input = {
    button: {
        focus: boxShadows.small,
    },
    checkbox: {
        focus: boxShadows.small,
    },
    switch: {
        focus: boxShadows.small,
    },
    radioButton: {
        focus: boxShadows.small,
    },
};

export default {
    colors,
    input,
    breakpoints,
    responsiveBreakpoints,
    medium,
    large,
    xlarge,
    spacing,
    frameSpacing,
    borderRadius,
    boxShadows,
    ...theme,
};
