import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { css } from '@emotion/react';
import { VisualCheckbox } from '../../checkbox-component';
import {
    BodyTextRegular,
    HighlightedBodyTextBold,
} from '../../../../typograph/typograph-components';

const Image = styled.img`
    max-width: 100%;
    max-height: 55px;
    margin-right: 6px;
`;
const SelectedIcon = styled(FontAwesomeIcon)`
    margin-left: auto;
`;
const CheckboxWrapper = styled.div`
    padding-right: 10px;
`;
const Option = props => {
    const { img, label } = props.data;
    const { isSelected, isMulti, selectProps, value: optionValue } = props;
    const selectValue = selectProps.value?.value || selectProps.value;
    const isNullSelected = optionValue === null && selectValue === null;
    const isOptionSelected = isSelected || isNullSelected;

    return (
        <components.Option {...props}>
            {isMulti && (
                <CheckboxWrapper>
                    <VisualCheckbox checked={isOptionSelected} />
                </CheckboxWrapper>
            )}
            <Flex alignItems="center" width="100%">
                {img && <Image src={img} />}
                {label}
                {isOptionSelected && !isMulti && (
                    <SelectedIcon icon={faCheck} />
                )}
            </Flex>
        </components.Option>
    );
};

Option.propTypes = {
    isSelected: PropTypes.bool,
    selectProps: PropTypes.object,
};

export default Option;

const EditIcon = styled(FontAwesomeIcon)`
    margin-left: auto;
    color: ${({ theme }) => theme.colors.textSecondary};
`;
const Label = styled(BodyTextRegular)`
    display: flex;
`;
const SelectedLabel = styled(HighlightedBodyTextBold)`
    display: flex;
`;

export const EditableOption = ({ children, labelChildren, ...props }) => {
    const { label } = props.data;
    const LabelComponent = props.isSelected ? SelectedLabel : Label;
    return (
        <components.Option {...props}>
            <Flex alignItems="center" width="100%">
                {children}
                <LabelComponent
                    css={css`
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    `}
                >
                    {label}
                    {labelChildren}
                </LabelComponent>
                {props.onEdit && (
                    <EditIcon
                        icon={faPenToSquare}
                        size="lg"
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            props.onEdit(props.value);
                            props.selectProps.onMenuClose();
                        }}
                    />
                )}
            </Flex>
        </components.Option>
    );
};
EditableOption.propTypes = {
    ...Option.propTypes,
    children: PropTypes.any,
    onEdit: PropTypes.func,
    labelChildren: PropTypes.any,
};
