import { React, memo } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';

export const StyledMenuItem = styled(NavLink, {
    shouldForwardProp: prop => !['isMainItem'].includes(prop),
})`
    padding: 10px;
    height: 44px;
    transition: border-color 200ms ease-in-out, background 200ms ease-in-out,
        color 200ms ease-in-out;
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSizeNormal};
    margin-bottom: 5px;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-weight: 500;
    border: ${({ isMainItem }) => isMainItem && '2px solid transparent'};
    &:focus {
        box-shadow: none;
        border-color: ${({ theme }) => theme.colors.primaryColor15};
    }
    &.active {
        background: ${({ theme: { colors } }) => colors.primaryColor15};
        color: ${({ theme: { colors } }) => colors.primaryColor};
        border-color: transparent;

        &:hover {
            background: ${({ theme: { colors } }) => colors.primaryColor10};
            border-color: transparent;
        }
    }
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        background: ${({ size, theme }) =>
            size === 'small' ? theme.colors.primaryColor10 : 'none'};
        height: 34px;
        padding: ${({ size }) => (size === 'small' ? '5px' : '10px')} 10px;
        justify-content: flex-start;
        border-radius: ${({ theme }) => theme.borderRadius.medium};
        &:hover {
            text-decoration: none;
            background: ${({ theme: { colors } }) => colors.primaryColor15};
            cursor: pointer;
            border-color: ${({ theme }) => theme.colors.primaryColor15};
        }

        &.active {
            background: ${({ theme: { colors }, isMainItem }) =>
                isMainItem ? colors.primaryColor : colors.primaryColor15};
            color: ${({ theme: { colors }, isMainItem }) =>
                isMainItem ? colors.white : colors.primaryColor};

            &:hover {
                background: ${({ theme: { colors }, isMainItem }) =>
                    isMainItem ? colors.primaryColor : colors.primaryColor10};
            }
        }
    }
`;

const MenuItem = memo(({ to, size, children }) => {
    return (
        <StyledMenuItem to={to} size={size} isMainItem>
            {children}
        </StyledMenuItem>
    );
});

export default MenuItem;

MenuItem.propTypes = {
    to: PropTypes.string,
    size: PropTypes.string,
    children: PropTypes.node,
};
