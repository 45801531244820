import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { CSSTransition } from 'react-transition-group';

const Container = styled(Flex)`
    position: fixed;
    top: ${`${LIB_BUILD ? 0 : 52}px`};
    right: -600px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 354px;
    box-shadow: -6px 1px 8px 0 ${({ theme }) => theme.colors.boxShadowColor};
    background-color: ${({ theme }) => theme.colors.bgSecondary};
    border: solid 1px ${({ theme }) => theme.colors.borderPrimary};
    border-top: none;
    transition: all ease-in-out 0.15s;
    z-index: 9999;
    padding: 0;
    right: 0;

    &.showSlideInMenu-enter {
        right: -600px;
    }

    &.showSlideInMenu-enter-active {
        right: 0;
    }

    &.showSlideInMenu-enter-done {
        right: 0;
    }

    &.showSlideInMenu-exit {
        right: 0;
    }

    &.showSlideInMenu-exit-active {
        right: -600px;
    }
`;

const SlideInMenu = props => {
    const { showSlideInMenu } = props;
    const [vh, setVh] = useState(window.innerHeight - (LIB_BUILD ? 0 : 51));

    useEffect(() => {
        if (showSlideInMenu) {
            document.body.classList.add('hidden-scroll');
        } else {
            document.body.classList.remove('hidden-scroll');
        }
    }, [showSlideInMenu]);

    useEffect(() => {
        const handleResize = () =>
            setVh(window.innerHeight - (LIB_BUILD ? 0 : 51));
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const styles = {
        height: `${vh}px`,
    };

    return (
        <CSSTransition
            in={showSlideInMenu}
            timeout={150}
            classNames="showSlideInMenu"
            unmountOnExit
        >
            <Container style={styles}>{props.children}</Container>
        </CSSTransition>
    );
};

SlideInMenu.propTypes = {
    t: PropTypes.func,
    showSlideInMenu: PropTypes.bool,
};

SlideInMenu.defaultProps = {
    showSlideInMenu: false,
};

export default SlideInMenu;
