export const BASE_URL_SUPPORT_HELP =
    'http://support.mspecs.se/support/solutions';
export const SUPPORT_MSPECS_URL = 'http://support.mspecs.se/support';
export const SUPPORT_URL = `${SUPPORT_MSPECS_URL}/home`;

export const ORDER_LANTMATERI_SERVICES =
    'https://www.lantmateriet.se/sv/Kartor-och-geografisk-information/geodataprodukter/';
export const COOKIE_URL = 'https://www.mspecs.se/cookies/';

export const LEGACY_API_PREFIX = 'legacy-api';
export const LEGACY_API_PREFIX_PATH = LIB_BUILD ? '' : `/${LEGACY_API_PREFIX}`;
export const API_PREFIX = 'api';
export const API_PREFIX_PATH = LIB_BUILD ? '' : `/${API_PREFIX}`;

export const VERIFIED_URL = 'https://app.verified.eu';
export const CALENDAR_SUPPORT_URL = `${SUPPORT_MSPECS_URL}/search/solutions?term=kalender&authenticity_token=cs3J1zL6ljpzhktI%2FDw%2Feq1bbS6bExoAXklnSr0jBl0%3D`;
export const GDPR_URL = 'https://gdpr.kundenssida.se/';
